export const isToday = (someDate) => {
  if (someDate) {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }
  return false
}
export const getLastDayOfPrevMonth = (startDate) => {
  // Takes String and Returns String
  const date = new Date(startDate)
  date.setDate(1) // going to 1st of the month
  date.setHours(-23)
  return date.toISOString().split('T')[0]
}

export const getLastDayOfNextMonth = (startDate) => {
  // Takes String and Returns String
  const date = new Date(startDate)
  var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0)
  return lastDayOfMonth.toISOString().split('T')[0]
}

export const matchMonthStr = (monthNumber) => {
  const months = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
  }
  return months[monthNumber]
}

export const Templates = {
  ggd_80: 1,
  ggd_ahorro: 2,
  gestion_ahorro_corto_plazo: 3,
  fondo_mutuo_renta: 4,
  preferencial: 5
}

export const isFondoNameGGDOrAhorro = (fondoName) => {
  return fondoName.includes('FONDO MUTUO BCI GESTIÓN GLOBAL') && !fondoName.includes('AHORRO')
}

export const isFondoCodeGGD = (fondos, selectedFondoCode, checkTemplate = [Templates.ggd_80, Templates.preferencial]) => {
  if (fondos && selectedFondoCode) {
    return checkTemplate.includes(fondos.find(item => item.run === selectedFondoCode.value).template)
  }
}
