import { isEmpty } from 'lodash'

export const Role = {
  Admin: 'AdminBci',
  Manager: 'ManagerBci',
  Marketing: 'MarketingBci',
  Analyst: 'AnalystBci'
}

const checkRoles = (user, roles) => {
  if (!isEmpty(user.roles)) {
    return user.roles.some(r => roles.includes(r))
  }
  return false
}

export const isUserCanEditAssets = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}

export const isUserCanEditAssetsClass = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}

export const isUserCanEditIssuers = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}

export const isUserCanEditFondoBci = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}
export const isUserCanViewVisibleFund = (user) => {
  return checkRoles(user, [Role.Admin])
}

export const isUserCanEditHoldings = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}

export const isUserCanViewMenu = (user, roles) => {
  // menu roles are set at navbarmenu.jsx
  return checkRoles(user, roles)
}

export const isUserCanEditFactsheet = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager, Role.Marketing])
}

export const isUserCanEditReportHoldings = (user) => {
  return checkRoles(user, [Role.Admin, Role.Manager])
}
