export const coloresEstado = {
    bciGreen: '#00953a',
    bciRed: '#f66c2e',
    bciBlue: '#006fb9',
    bciYellow: '#ffd700',
    bciProBlackC: '#1e1e1e',
    bci5405C: '#50748a',
    bci5425C: '#FF8700',
    bci5435C: '#a7bac9',
    bci5455C: '#c0cdd5',
    navbar: '#FFFFFF',

}

export const coloresITAU = {
    bciGreen: '#00953a',
    bciRed: '#f66c2e',
    bciBlue: '#006fb9',
    bciYellow: '#ffd700',
    bciProBlackC: '#1e1e1e',
    bci5405C: '#50748a',
    bci5425C: '#FF8700',
    bci5435C: '#a7bac9',
    bci5455C: '#c0cdd5',
    navbar: '#FFFFFF',
}

export const coloresBanchile = {
    bciGreen: '#00953a',
    bciRed: '#f66c2e',
    bciBlue: '#006fb9',
    bciYellow: '#ffd700',
    bciProBlackC: '#1e1e1e',
    bci5405C: '#50748a',
    bci5425C: '#FF8700',
    bci5435C: '#a7bac9',
    bci5455C: '#c0cdd5',
    navbar: '#FFFFFF',
}
const URL = process.env.PUBLIC_URL

export const UIClients = {
    "Banco Estado": {
        "colors": coloresEstado,
        "logo": `${URL}/Logo BE AGF (H).png`
    },
    "Banchile": {
        "colors": coloresBanchile,
        "logo": `${URL}/logo_banchile.png`
    },
    "AGF ITAU": {
        "colors": coloresITAU,
        "logo": `${URL}logo_itau.png`
    },
    "AGF LV": {
        "colors": coloresITAU,
        "logo": `${URL}logo_itau.png`
    },
    "AGF Toesca": {
        "colors": coloresITAU,
        "logo": `${URL}logo_itau.png`
    }
}

export const risk_clients = [
    //BancoEstado
    {
        2: 'Conservador',
        3: 'Moderado',
        4: 'Decidido',
    },
    //ITAU
    {
        2: 'Conservador',
        3: 'Moderado',
        4: 'Balanceado',
        5: 'Arriesgado'
    }
]

export const categoryUnderlyingAssetDict = {
    99: "Not Assigned",
    1: "Money Market",
    2: "Money Market Dólar",
    3: "Deuda",
    4: "Deuda Internacional",
    5: "Balanceado",
    6: "Accionario",
}

export const category_fund_dict = [
    {
        id: 1,
        label: 'Money Market',
        options: []
    },
    {
        id: 2,
        label: 'Money Market Dólar',
        options: []
    },
    {
        id: 3,
        label: 'Deuda',
        options: []
    },
    {
        id: 4,
        label: 'Deuda Internacional',
        options: []
    },
    {
        id: 5,
        label: 'Balanceado',
        options: []
    },
    {
        id: 6,
        label: 'Accionario',
        options: []
    }

]