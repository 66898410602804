import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Img from 'react-image'

import 'react-toastify/dist/ReactToastify.min.css'
import clsx from 'clsx'
// Material
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

// Material Icons
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

// Custom
import { isEmpty } from 'lodash'
import AuthContext from '../../context/authContext'
import { mainListItems } from '../../consts/navbarmenu'
import { UIClients } from '../../consts/clients'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: '#11354B',
    backgroundColor: 'white'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}))

const HeaderToolbar = (props) => {
  const { AuthState, AuthDispatch } = useContext(AuthContext)
  const user = AuthState ? AuthState.userData : null
  const logged = !isEmpty(user)
  const logOut = () => {
    AuthDispatch({ type: 'LOGOUT' })
  }
  const client = AuthState ? AuthState.clientData[0] : null
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar
      />
      <AppBar

        className={clsx(classes.appBar, open && classes.appBarShift)}
        position='absolute'
        style={{
          backgroundColor: UIClients[client.name]["colors"].navbar
        }}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color={UIClients[client.name]["colors"].primary}
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            <IconButton color='inherit' className={classes.toolbarIcon}>
              <Link to='/' style={{ color: 'inherit', textDecoration: 'inherit' }}>
                <Img src={UIClients[client.name]["logo"]} alt={client.name} style={{ height: 40 }} />
              </Link>
            </IconButton>
          </Typography>

          {logged &&
            <IconButton color={UIClients[client.name]["colors"].primary} className={classes.toolbarIcon} onClick={() => logOut()}>
              <Typography component='h6' variant='h6' color='inherit' noWrap>
                Logout
              </Typography>
            </IconButton>}
          {!logged &&
            <IconButton color='inherit' className={classes.toolbarIcon}>
              <Link to='/' style={{ color: UIClients[client.name]["colors"].primary, textDecoration: 'inherit' }}>
                <Typography component='h6' variant='h6' color='inherit' noWrap>
                  Log in
                </Typography>
              </Link>
            </IconButton>}
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton
            onClick={handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems(AuthState)}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {props.children}
        </Container>
      </main>
    </div>
  )
}

export default HeaderToolbar
