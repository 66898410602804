import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import ListIcon from '@material-ui/icons/List'
import AppsIcon from '@material-ui/icons/Apps'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import FunctionsIcon from '@material-ui/icons/Functions'
import Tooltip from '@material-ui/core/Tooltip'
import PublishIcon from '@material-ui/icons/Publish'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import HomeIcon from '@material-ui/icons/Home'
import BusinessIcon from '@material-ui/icons/Business'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { NavLink } from 'react-router-dom'
import { map } from 'lodash'
import { colores, colorArray } from './colors'
import { Role, isUserCanViewMenu } from './role'


export const buttonList = [
  { icon: <AppsIcon />, text: 'Assets', color: colorArray[0], link: 'assets', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <ListIcon />, text: 'Holdings', color: colorArray[1], link: 'holdings', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <BusinessIcon />, text: 'Emisores', color: colorArray[1], link: 'issuers', roles: [Role.Admin, Role.Manager] },
  { icon: <AccessTimeIcon />, text: 'Duraciones', color: colorArray[1], link: 'durations', roles: [Role.Admin, Role.Manager] },
  { icon: <GroupWorkIcon />, text: 'AssetClasses', color: colorArray[2], link: 'assetclasses', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <BubbleChartIcon />, text: 'Fondos Mutuos', color: colorArray[3], link: 'funds', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <EqualizerIcon />, text: 'Charts', color: colorArray[4], link: 'charts', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <FindInPageIcon />, text: 'Factsheets', color: colorArray[5], link: 'factsheets', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <FunctionsIcon />, text: 'Portfolio Stats', color: colorArray[6], link: 'reports', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <ShowChartIcon />, text: 'Rentabilidades', color: colorArray[7], link: 'rentabilidad', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <PublishIcon />, text: 'Holding Import', color: colorArray[8], link: 'holding_import', roles: [Role.Admin, Role.Manager] },
  { icon: <FindInPageIcon />, text: 'Holding Report', color: colorArray[3], link: 'holding_report', roles: [Role.Admin, Role.Manager] },
]

export const buttonFactsheetList = [
  { icon: <HomeIcon />, text: 'Inicio', color: colorArray[5], link: 'menu', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <FindInPageIcon />, text: 'Individual', color: colorArray[4], link: 'factsheets/configurar', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <FindInPageIcon />, text: 'Masivos', color: colorArray[4], link: 'factsheets/generador', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  { icon: <FindInPageIcon />, text: 'Definitivos', color: colorArray[4], link: 'factsheets/definitivo', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] }
]

export const NavbarButtonList = [
  { icon: <HomeIcon />, text: 'Inicio', color: colores.bciProBlackC, link: 'menu', roles: [Role.Admin, Role.Manager, Role.Marketing, Role.Analyst] },
  ...buttonList
]

export const mainListItems = (user) => {
  return (
    <div>
      {map(NavbarButtonList, (button, index) => {
        if (isUserCanViewMenu(user, button.roles)) {
          return (
            <NavLink key={'buttonList_' + index} to={'/' + button.link} style={{ color: 'inherit', textDecoration: 'inherit' }}>
              <Tooltip title={button.text} placement='right'>
                <ListItem button>
                  <ListItemIcon style={{ color: button.color }}>
                    {button.icon}
                  </ListItemIcon>
                  <ListItemText primary={button.text} />
                </ListItem>
              </Tooltip>
            </NavLink>
          )
        }
      })}
    </div>
  )
}
