import { isToday } from '../consts/utils'

const localToken = JSON.parse(localStorage.getItem('localToken'))
const localUserData = JSON.parse(localStorage.getItem('localUserData'))
const localDate = new Date(JSON.parse(localStorage.getItem('localDate')))
const localMinMaxDates = JSON.parse(localStorage.getItem('localMinMaxDates'))
const localClientData = JSON.parse(localStorage.getItem('localClientData'))

export const authState = {
  userData: localUserData || null,
  token: localToken || null,
  roles: localUserData ? localUserData.roles : null,
  minMaxDates: localDate && isToday(localDate) ? localMinMaxDates : null,
  localDate,
  clientData: localClientData,
  msal: null
}
const authReducer = (state, action) => {
  const minMaxDates = action.minMaxDates
  const clientData = action.clientData

  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('localToken', JSON.stringify(action.token))
      localStorage.setItem('localUserData', JSON.stringify(action.userData))
      return { token: action.token, roles: action.roles, userData: action.userData, minMaxDates: state.minMaxDates, clientData: state.clientData, msal: action?.msal }
    case 'LOGOUT':
      localStorage.clear()
      return { userData: null, token: null, roles: null, msal: null, minMaxDates: state.minMaxDates, clientData: state.clientData }
    case 'MINMAX_DATES':
      localStorage.setItem('localDate', JSON.stringify(new Date()))
      localStorage.setItem('localMinMaxDates', JSON.stringify(minMaxDates))
      return { ...state, minMaxDates, localDate: new Date() }
    case 'CLIENT_DATA':
      localStorage.setItem('localClientData', JSON.stringify(clientData))
      return { ...state, clientData }

    default:
      throw new Error('Unexpected action')
  }
}

export default authReducer
