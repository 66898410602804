import { reverse, take } from 'lodash'

export const colores = {
  bciGreen: '#506182',
  bciRed: '#FF6C2C',
  bciBlue: '#1b5494',
  bciYellow: '#4A4A4A',
  bciProBlackC: '#F08329',
  bci5405C: '#50748a',
  bci5425C: '#72879B',
  bci5435C: '#C8C2BD',
  bci5455C: '#868686',
  navbar: '#72879B'
}

export const colorArray = [
  colores.bciBlue,
  colores.bciGreen,
  colores.bciRed,
  colores.bciYellow,
  colores.bci5405C,
  colores.bci5425C,
  colores.bci5435C,
  colores.bci5455C,
  colores.bciProBlackC
]


export const invertedColorList = (n) => {
  return reverse(take(colorArray, n))
}
