// App.js
import React, { useReducer, Suspense, lazy } from 'react'
import axios from 'axios'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { toast } from 'react-toastify'
import { get } from 'lodash'
import Typography from '@material-ui/core/Typography'
import authReducer, { authState } from '../reducers/auth'
import AuthContext from '../context/authContext'
import Header from '../components/Header/header'
/*import NotFoundPage from '../components/NotFoundPage' */
const Login = lazy(() => import('../components/Login'))
const Assets = lazy(() => import('../components/Assets'))
const ChartsView = lazy(() => import('../components/Charts'))
const FactsheetsView = lazy(() => import('../components/Factsheets'))
const Holdings = lazy(() => import('../components/Holdings'))
const Durations = lazy(() => import('../components/Durations'))
const HoldingImportFlow = lazy(() => import('../components/HoldingImportFlow'))
const Issuers = lazy(() => import('../components/Issuers'))
const MainMenu = lazy(() => import('../components/MainMenu'))
const FactsheetsMenu = lazy(() => import('../components/FactsheetsMenu'))
const FactsheetsGeneratorView = lazy(() => import('../components/FactsheetsGenerator'))
const FactsheetsFinalView = lazy(() => import('../components/FactsheetsFinal'))
const AssetClassList = lazy(() => import('../components/AssetClasses'))
const ReportsView = lazy(() => import('../components/Reports'))
const FundsList = lazy(() => import('../components/Funds'))
const RentabilidadView = lazy(() => import('../components/Rentabilidad'))
const HoldingReportView = lazy(() => import('../components/HoldingReport'))

export const history = createBrowserHistory()

function Copyright(AuthState) {
  const holding_updated = get(AuthState.AuthState, 'minMaxDates.max_hol_date', null)
  const sv_date = get(AuthState.AuthState, 'minMaxDates.max_vc_date', null)
  const style = {
    position: 'fixed',
    width: 'calc(100% - 128px)',
    bottom: 0,
    textAlign: 'center',
    backgroundColor: 'white'
  }
  return (
    <Typography variant='body2' color='textSecondary' align='center' style={style}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
      {' All rights reserved.'}
      {' '}
      Powered by {' '}
      <a style={{ color: '#ea6d20' }} target='_blank' rel='noopener noreferrer' href='https://abaqus.cl/'>
        Abaqus
      </a>
      {' '}
      {' /// '}
      {' '}
      <strong>
        Última actualización: Valor cuota: {sv_date}, Holdings: {holding_updated}
      </strong>
    </Typography>
  )
}


const PublicWithHeader = (Children, AuthState, AuthDispatch) => {
  return (
    <AuthContext.Provider value={{ AuthState, AuthDispatch }}>
      <Children history={history} />
    </AuthContext.Provider>
  )
}

toast.configure()
/* global FileReader */
/* global Blob */
const App = () => {
  const [AuthState, AuthDispatch] = useReducer(authReducer, authState)

  // intercepting when request has invalid token with error 401
  axios.interceptors.response.use(async (config) => {
    return config
  }, function (error) {
    if (error.response?.status === 401) {
      AuthDispatch({ type: 'LOGOUT' })
      toast.warning('¡Por favor, vuelve a iniciar sesión para obtener el nuevo token!', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }

    if (
      error.request?.responseType === 'blob' && error.response?.data instanceof Blob
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result)
          resolve(Promise.reject(error))
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }

    return Promise.reject(error)
  })
  const fallback = <div>Cargando...</div>
  let lastLocation = ''
  if (!AuthState.token) {
    lastLocation = window.location.pathname
  }
  return (
    <BrowserRouter>
      <>
        <Switch>
          <Suspense fallback={fallback}>
            <Route exact path='/' component={() => PublicWithHeader(Login, AuthState, AuthDispatch)} />
          </Suspense>

        </Switch>
      </>

      <>
        <AuthContext.Provider value={{ AuthState, AuthDispatch }}>
          {AuthState.token ? (
            <Header>

              <Suspense fallback="">
                <Switch>
                  <Route path='/menu' component={MainMenu} />
                  <Route path='/assets' component={Assets} />
                  <Route path='/assetclasses' component={AssetClassList} />
                  <Route path='/funds' component={FundsList} />
                  <Route path='/holdings' component={Holdings} />
                  <Route path='/durations' component={Durations} />
                  <Route path='/holding_import' component={HoldingImportFlow} />
                  <Route path='/holding_report' component={HoldingReportView} />
                  <Route path='/issuers' component={Issuers} />
                  <Route path='/charts' component={ChartsView} />
                  <Route exact path='/factsheets' component={FactsheetsMenu} />
                  <Route exact path='/factsheets/configurar' component={FactsheetsView} />
                  <Route exact path='/factsheets/generador' component={FactsheetsGeneratorView} />
                  <Route exact path='/factsheets/definitivo' component={FactsheetsFinalView} />
                  <Route path='/reports' component={ReportsView} />
                  <Route path='/rentabilidad' component={RentabilidadView} />

                </Switch>

              </Suspense>
              <Copyright AuthState={AuthState} />

            </Header>
          ) : (lastLocation === '/' ? lastLocation = '' :
            < Redirect to={{ pathname: '/', search: `?lastLocation=${lastLocation}` }} />
          )}
        </AuthContext.Provider>

      </>
    </BrowserRouter >
  )
}

export default App
